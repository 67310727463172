<app-modal #modal class="diary-config-modal">
  <div class="modal-header">
    <h2 class="modal-title">E-Diary Settings</h2>
    <button type="button" class="close" (click)="modal.hide()" aria-label="Close">
      <span aria-hidden="true"><i class="far fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">

      <div class="form-group">
        <label class="timezone-label">Patient Timezone <sup *ngIf="isRequired('timezone')">*</sup></label>
        <app-timezone-select #timezoneSelect (optionChanged)="onTimezoneChanged($event)" [parentForm]="form" controlName="timezone"></app-timezone-select>
      </div>

      <section>
        <div class="checkbox-wrap">
          <label class="checkbox" [ngClass]="{'checked': form.get('visitRemindersEnabled').value}">
            <input type="checkbox" formControlName="visitRemindersEnabled" value="true">
            <span></span>
          </label>
          <label class="checkbox-label">Enable Visit Reminders</label>
        </div>
      </section>

      <section>
        <div class="checkbox-wrap">
          <label class="checkbox" [ngClass]="{'checked': form.get('urineRemindersEnabled').value}">
            <input type="checkbox" id="urineRemindersEnabled" formControlName="urineRemindersEnabled" value="true">
            <span></span>
          </label>
          <label class="checkbox-label" for="urineRemindersEnabled">Enable Urine Reminders</label>
        </div>

        <div class="section-inner" [ngClass]="{ 'visibility-hidden': form.get('urineRemindersEnabled').value !== true }">
          <div class="field-group">
            <div class="field-group-inner">
              <label>Urine Reminder Time <sup *ngIf="isRequired('urineReminderTime')">*</sup></label>
              <div class="input-wrap type-time">
                <mat-form-field>
                  <input type="time" matInput formControlName="urineReminderTime">
                </mat-form-field>
              </div>
            </div>
            <span class="helper-text" *ngIf="shouldShowUtcTime('urineReminderTime')">{{ form.get('urineReminderTimeUtc').value }} UTC</span>
          </div>
        </div>
      </section>

      <section>
        <div class="checkbox-wrap">
          <label class="checkbox" [ngClass]="{'checked': form.get('dailyDoseRemindersEnabled').value}">
            <input type="checkbox" id="dailyDoseRemindersEnabled" formControlName="dailyDoseRemindersEnabled"
                   value="true">
            <span></span>
          </label>
          <label class="checkbox-label" for="dailyDoseRemindersEnabled">Enable Daily Dose Reminders</label>
        </div>
      </section>

      <div class="two-column-inputs">
        <div class="field-group one-third">
          <div class="field-group-inner">
            <label>Daily Dose First Date <sup *ngIf="isRequired('startDate')">*</sup></label>
            <div class="input-wrap">
              <app-datepickerv2-input [parentForm]="form" controlName="startDate"></app-datepickerv2-input>
            </div>
          </div>
        </div>

        <div class="field-group one-third">
          <div class="field-group-inner">
            <label>Daily Dose Last Date <sup *ngIf="isRequired('endDate')">*</sup></label>
            <div class="input-wrap">
              <app-datepickerv2-input [parentForm]="form" controlName="endDate"></app-datepickerv2-input>
            </div>
          </div>
        </div>

        <div class="field-group one-third">
          <div class="field-group-inner">
            <label>Dosage Time<sup *ngIf="isRequired('endDate')">*</sup></label>
            <div class="input-wrap type-time">
              <mat-form-field  *ngIf="!editingDisabled">
                <input type="time" matInput formControlName="dailyDoseReminderTime">
              </mat-form-field>
              <div class="fake-field disabled" *ngIf="editingDisabled">{{ form.get('dailyDoseReminderTime').value }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="two-column-inputs">
        <div class="field-group">
          <div class="field-group-inner">
            <label>Dose (amount of pills) <sup *ngIf="isRequired('dosage')">*</sup></label>
            <div class="input-wrap">
              <div class="fake-field disabled" *ngIf="editingDisabled">{{ form.get('dosage').value }}</div>
              <input type="number" formControlName="dosage" *ngIf="!editingDisabled">
            </div>
          </div>
        </div>

        <div class="field-group">
          <div class="field-group-inner">
            <label>Dose (text) <sup *ngIf="isRequired('dosageText')">*</sup></label>
            <div class="input-wrap">
              <div class="fake-field disabled" *ngIf="editingDisabled">{{ form.get('dosageText').value }}</div>
              <input type="text" formControlName="dosageText" *ngIf="!editingDisabled">
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="onFormSubmit()" [disabled]="form.invalid || form.get('isBusy').value" class="btn btn-primary">
      <span *ngIf="form.get('isBusy').value">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
      <span *ngIf="!form.get('isBusy').value">Update Settings</span>
    </button>
  </div>
</app-modal>
