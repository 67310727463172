import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { PatientService } from "../../../../core/services/patient.service";
import { PatientDiaryConfig } from "../../../../core/models/patient-diary-config.model";
import { AlertService } from "../../../../shared/alert/alert.service";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import moment from 'moment-timezone';
import { TimezoneSelectComponent } from "../../../../shared/timezone-select/timezone-select.component";
import { DateHelper } from "../../../../core/helpers/date-helper";
import { UpdateDiaryConfig } from "../../../../core/services/interfaces/update-diary-config.interface";
import { LogHelper } from "../../../../core/helpers/log.helper";

@Component({
  selector: 'app-diary-config-modal',
  templateUrl: './diary-config-modal.component.html',
  styleUrl: './diary-config-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DiaryConfigModalComponent {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('lastDateInput') lastDateInput: ElementRef;
  @ViewChild('timezoneSelect') timezoneSelect: TimezoneSelectComponent;

  @Input('patientTrialId') patientTrialId: string;
  @Input('hasSubmissions') hasSubmissions: boolean;

  @Output('changed') changed = new EventEmitter();

  private readonly _patientService = inject(PatientService);
  private readonly _alertService = inject(AlertService);
  private readonly _ngZone = inject(NgZone);

  diaryConfig: PatientDiaryConfig = new PatientDiaryConfig();
  loaded = false;
  form: FormGroup;
  editingDisabled = false;

  constructor() {
    this.form = new FormGroup({
      isBusy: new FormControl<boolean>(false),
      timezone: new FormControl<string>('', Validators.required),
      timezoneLabel: new FormControl(''),
      visitRemindersEnabled: new FormControl(false),
      urineRemindersEnabled: new FormControl(false),
      dailyDoseRemindersEnabled: new FormControl(true),
      originalStartDate: new FormControl(),
      originalEndDate: new FormControl(),
      startDate: new FormControl<string>(''),
      endDate: new FormControl<string>(''),
      urineReminderTime: new FormControl(''),
      urineReminderTimeUtc: new FormControl(''),
      dailyDoseReminderTime: new FormControl(''),
      dailyDoseReminderTimeUtc: new FormControl(''),
      dosage: new FormControl<number>(0),
      dosageText: new FormControl('')
    });

    // Update reminder times when timezone changed
    this.form.get('timezone').valueChanges.subscribe(value => {
      this.updateTimeUtc('urineReminderTime', 'urineReminderTimeUtc');
      this.updateTimeUtc('dailyDoseReminderTime', 'dailyDoseReminderTimeUtc');
    });

    this.form.get('startDate').valueChanges.subscribe(enabled => {
      this.validateDoseDates();
      this.setValidators();
    });

    this.form.get('endDate').valueChanges.subscribe(enabled => {
      this.validateDoseDates();
      this.setValidators();
    });

    this.form.get('visitRemindersEnabled').valueChanges.subscribe(enabled => {
      this.setValidators();
    });

    this.form.get('urineRemindersEnabled').valueChanges.subscribe(enabled => {
      this.setValidators();
    });

    this.form.get('dailyDoseRemindersEnabled').valueChanges.subscribe(enabled => {
      this.setValidators();
    });

    // Convert urine reminder time to UTC
    this.form.get('urineReminderTime').valueChanges.subscribe(value => {
      this.updateTimeUtc('urineReminderTime', 'urineReminderTimeUtc');
    });

    // Convert daily dose reminder time to UTC
    this.form.get('dailyDoseReminderTime').valueChanges.subscribe(value => {
      this.updateTimeUtc('dailyDoseReminderTime', 'dailyDoseReminderTimeUtc');
    });


  }

  protected setValidators() {
    // Urine reminders
    if (this.form.get('urineRemindersEnabled').value) {
        this.form.get('urineReminderTime').setValidators(Validators.required);
    } else {
        this.form.get('urineReminderTime').setValidators(null);
    }
    this.form.get('urineReminderTime').updateValueAndValidity();

    // Daily reminders
    if (this.form.get('dailyDoseRemindersEnabled').value) {
      this.form.get('dailyDoseReminderTime').setValidators(Validators.required);

      if (this.form.get('startDate').value && this.form.get('endDate').value) {
        this.form.get('dosage').setValidators([Validators.required, Validators.min(1)]);
        this.form.get('dosageText').setValidators(Validators.required);
      }
    } else {
      this.form.get('dailyDoseReminderTime').setValidators(null);
      this.form.get('dosage').setValidators(null);
      this.form.get('dosageText').setValidators(null);
    }

    this.form.get('dailyDoseReminderTime').updateValueAndValidity();
    this.form.get('dosage').updateValueAndValidity();
    this.form.get('dosageText').updateValueAndValidity();
  }

  isRequired(controlName: string): boolean {
    const control = this.form.get(controlName);
    if (!control) {
      return false;
    }
    const validator = control.validator ? control.validator({} as AbstractControl) : null;
    return validator ? validator.required : false;
  }

  show(): void {
    this.loaded = false;
    this.loadDiaryConfig();
    this.modal.show();
  }

  hide(): void {
    this.modal.hide();
  }

  shouldShowUtcTime(sourceControl: string): boolean {
    let time = this.form.get(sourceControl).value;
    let timezoneStr = this.form.get('timezone').value;

    return time !== '' && timezoneStr !== null && timezoneStr !== '';
  }

  validateDoseDates() {
    const originalStartDate = this.form.get('originalStartDate').value;
    const originalEndDate = this.form.get('originalEndDate').value;
    const newStartDate = this.form.get('startDate').value;
    const newEndDate = this.form.get('endDate').value;

    if (newStartDate !== originalStartDate && originalStartDate && newStartDate) {
      const diff = newStartDate.getTime() - originalStartDate.getTime();
      if (diff > 0) {
        this.form.patchValue({startDate: originalStartDate});
        this._alertService.showWarningAlert('New start date cannot be after the current start date.');
      }
    }

    if (newEndDate != originalEndDate && newEndDate && originalEndDate) {
      const diff = originalEndDate.getTime() - newEndDate.getTime();
      if (diff > 0) {
        this.form.patchValue({endDate: originalEndDate});
        this._alertService.showWarningAlert('New end date cannot be before the current end date.');
      }
    }
  }

  updateTimeUtc(sourceControl: string, targetControl: string) {
    let time = this.form.get(sourceControl).value;
    let timezoneStr = this.form.get('timezone').value;

    if (time === null || time === '' || timezoneStr === null || timezoneStr === '') {
      this.form.patchValue({ targetControl: '' });
      return;
    }

    let timezone = this.form.get('timezone').value;
    if (timezone === null || timezone === '')
      return;

    this.form.get(targetControl).setValue(this.convertToUTC(time, timezone));
  }

  /**
   * Loads the diary configuration for the current patient trial.
   * It fetches the configuration via the patient service and
   * assigns it to the local diaryConfig property.
   * Also handles any errors by showing an alert and setting the loaded flag.
   *
   * @return {void} No return value.
   */
  loadDiaryConfig(): void {
    this._patientService.patientDiaryConfig(this.patientTrialId).subscribe({
      next: config => {
        this.diaryConfig = config;

        // Set daily dose reminders to true if diary entries have not been created yet
        if (config.startDate === null && config.endDate === null)
          config.dailyDoseRemindersEnabled = true;

        // Set default times
        if (config.urineReminderTimeMinutes === null) {
          config.urineReminderTimeMinutes = 1260; // 21:00
        }

        if (config.dailyDoseReminderTimeMinutes === null) {
          config.dailyDoseReminderTimeMinutes = 480; // 08:00
        }

        LogHelper.log(config);

        this.form.patchValue({
          timezone: config.timezone,
          visitRemindersEnabled: config.visitRemindersEnabled,
          urineRemindersEnabled: config.urineRemindersEnabled,
          dailyDoseRemindersEnabled: config.dailyDoseRemindersEnabled,
          originalStartDate: config.startDate !== null ? new Date(config.startDate) : null,
          originalEndDate: config.endDate !== null ? new Date(config.endDate) : null,
          startDate: config.startDate !== null ? new Date(config.startDate) : null,
          endDate: config.endDate !== null ? new Date(config.endDate) : null,
          urineReminderTime: DateHelper.convertMinutesTo24HourTime(config.urineReminderTimeMinutes),
          dailyDoseReminderTime: DateHelper.convertMinutesTo24HourTime(config.dailyDoseReminderTimeMinutes),
          dosage: config.dosage,
          dosageText: config.dosageText
        });

        // Set the value on the timezone dropdown
        this.timezoneSelect.setTimezone(config.timezone);

        if (config.startDate && config.endDate) {
          this.editingDisabled = true;
        }

        this.setValidators();

        this.loaded = true;
      },
      error: (error) => {
        console.log(error);
        this._alertService.showErrorAlert(error);
        this.loaded = true;
      }
    })
  }

  private convertDateStringToDate(str: string): Date {
    return str !== null && str !== '' ? moment(str + ' 00:00', 'DD/MM/YYYY HH:mm').toDate() : null;
  }

  /**
   * When the timezone is changed we need to get the full text label of the selected timezone to show in the disabled
   * timezone input
   * @param option
   */
  onTimezoneChanged(option: { value: string, text: string }) {
    if (option)
      this.form.patchValue({timezoneLabel: option.text});
  }

  onFormSubmit(): void {
    if (this.form.invalid)
      return;

    this.form.patchValue({isBusy: true});

    const form = this.form;

    let dto: UpdateDiaryConfig = {
      startDate: DateHelper.dateToYyyyMmDd(form.value.startDate),
      endDate: DateHelper.dateToYyyyMmDd(form.value.endDate),
      timezone: form.value.timezone,
      dosage: form.value.dosage,
      dosageText: form.value.dosageText,
      visitRemindersEnabled: form.value.visitRemindersEnabled,
      urineRemindersEnabled: form.value.urineRemindersEnabled,
      dailyDoseRemindersEnabled: form.value.dailyDoseRemindersEnabled,
      urineReminderTimeMinutes: DateHelper.convert24HourTimeToMinutes(form.value.urineReminderTime),
      dailyDoseReminderTimeMinutes: DateHelper.convert24HourTimeToMinutes(form.value.dailyDoseReminderTime),
      urineReminderTimeMinutesUtc: DateHelper.convert24HourTimeToMinutes(form.value.urineReminderTimeUtc),
      dailyDoseReminderTimeMinutesUtc: DateHelper.convert24HourTimeToMinutes(form.value.dailyDoseReminderTimeUtc),
    }

    this._patientService.updatePatientDiaryConfig(this.patientTrialId, dto).subscribe({
      next: rsp => {
        this.form.patchValue({isBusy: false});
        this._alertService.showSuccessAlert('Settings updated successfully');
        this.changed.emit();
        this.hide();
      },
      error: error => {
        console.log(error);
        this._alertService.showErrorAlert(error);
        this.form.patchValue({isBusy: false});
      }
    })
  }

  convertToUTC(time: string, timezone: string): string {
    // Parse the input time and timezone
    const timeInZone = moment.tz(time, "HH:mm", timezone);

    // Convert to UTC and format it as a 24-hour string
    const timeInUTC = timeInZone.utc().format("HH:mm");

    return timeInUTC;
  }

}
