import { AlertService } from 'app/shared/alert/alert.service';
import { CaxtonCardService } from './../../../core/services/caxton-card.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { enumToText } from 'app/core/helpers/enum-to-text.function';
import {
  AddCaxtonCardRequest,
  AssignCardsRequest,
  CaxtonCardDelivery,
  CaxtonCardStatus,
  CaxtonCardTypeFilter,
  CaxtonCardViewModel,
  ExportCaxtonCardsRequest,
  GetCaxtonCardsRequest,
  MarkCaxtonCardAs,
  UpdateCaxtonCardRequest,
  UpdateCaxtonCardsDeliveryStatusRequest
} from 'app/core/models/card-tracker.model';
import { ResponsePage } from 'app/core/models/search-page.model';
import { SelectOption } from 'app/core/models/select-option.model';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { ModalV2Component } from 'app/shared/modal-v2/modal-v2.component';
import { TrialService } from 'app/core/services/trial.service';
import { LogHelper } from 'app/core/helpers/log.helper';
import { SiteService } from 'app/core/services/site.service';
import { TrialAutocompleteComponent } from 'app/shared/trial-autocomplete/trial-autocomplete.component';
import { TrialAutocomplete } from 'app/shared/trial-autocomplete/trial-autocomplete.model';
import { TrialWithSites } from 'app/core/models/trial-detail.model';
import { ModalComponent } from 'app/shared/modal/modal.component';
import moment from 'moment';
import { Permissions } from "../../../core/constants/permissions";
import { AuthService } from "../../../core/services/auth.service";
import { RoleNames } from "../../../core/constants/role-names";

@Component({
  selector: 'app-caxton-cards-list',
  templateUrl: './caxton-cards-list.component.html',
  styleUrl: './caxton-cards-list.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CaxtonCardsListComponent implements OnInit, AfterViewInit {
  @ViewChild('cardTypeSelect') cardTypeSelect: DropdownInputComponent;
  @ViewChild('markAsSelect') markAsSelect: DropdownInputComponent;
  @ViewChild('assignCardsSiteDropdown') assignCardsSiteDropdown: DropdownInputComponent;
  @ViewChild('addCardsModal') addCardsModal: ModalV2Component;
  @ViewChild('assignCardsModal') assignCardsModal: ModalV2Component;
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('exportModal') exportModal: ModalComponent;
  @ViewChild('exportTrialAutocomplete') exportTrialAutocomplete: TrialAutocompleteComponent;

  cardTypeOptions: SelectOption[] = [];
  markAsOptions: SelectOption[] = [];
  deliveryOptions: SelectOption[] = [];
  trialOptions: SelectOption[] = [];
  siteOptions: Record<string, SelectOption[]> = {};
  assignCardsSiteOptions: SelectOption[] = [];
  cardStatusOptions: SelectOption[] = [];
  trialsList: TrialWithSites[] = [];

  cards: ResponsePage<CaxtonCardViewModel>;
  selectedCards: string[] = [];

  addCardsForm = new FormGroup({
    numberOfCards: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
    lastCardNo: new FormControl<number>(null, Validators.required),
  });

  searchForm = new FormGroup({
    trialId: new FormControl(''),
    searchTerm: new FormControl('')
  });

  assignCardsForm = new FormGroup({
    trialId: new FormControl<string>(null, Validators.required),
    siteId: new FormControl<string>(null),
    siteContact: new FormControl<string>(null),
    patientDetails: new FormControl<string>(null),
  });

  exportForm = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
    trialId: new FormControl('', [Validators.required])
  });

  updateCardForms: Record<string, FormGroup> = {};

  processingModalRequest: boolean = false;
  loading: boolean = false;

  assignCardPatientView: boolean = false;

  CaxtonCardTypeFilter = CaxtonCardTypeFilter;
  page: number;

  constructor(private caxtonCardService: CaxtonCardService, private alertService: AlertService, public _authService: AuthService,
    private cd: ChangeDetectorRef, private trialService: TrialService,
    private siteService: SiteService) { }

  ngOnInit(): void {
    this.initCardTypeOptions();
    this.initMarkAsOptions();
    this.initDeliveryOptions();
    this.initCardStatusOptions();
    this.initTrialOptions();
  }

  ngAfterViewInit(): void {
    const mainContainer = document.querySelector('.main-container');
    mainContainer.classList.add('full-width');

    this.cd.detectChanges();
  }

  initMarkAsOptions() {
    this.cardTypeOptions.push({ value: CaxtonCardTypeFilter.AllCards.toString(), text: enumToText(CaxtonCardTypeFilter, CaxtonCardTypeFilter.AllCards) });
    this.cardTypeOptions.push({ value: CaxtonCardTypeFilter.Missing.toString(), text: enumToText(CaxtonCardTypeFilter, CaxtonCardTypeFilter.Missing) });
    this.cardTypeOptions.push({ value: CaxtonCardTypeFilter.NotSent.toString(), text: enumToText(CaxtonCardTypeFilter, CaxtonCardTypeFilter.NotSent) });
  }

  initCardTypeOptions() {
    this.markAsOptions.push({ value: MarkCaxtonCardAs.Received.toString(), text: enumToText(MarkCaxtonCardAs, MarkCaxtonCardAs.Received) });
    this.markAsOptions.push({ value: MarkCaxtonCardAs.Sent.toString(), text: enumToText(MarkCaxtonCardAs, MarkCaxtonCardAs.Sent) });
    this.markAsOptions.push({ value: MarkCaxtonCardAs.Missing.toString(), text: enumToText(MarkCaxtonCardAs, MarkCaxtonCardAs.Missing) });
  }

  initDeliveryOptions() {
    this.deliveryOptions.push({ value: CaxtonCardDelivery.Received.toString(), text: enumToText(CaxtonCardDelivery, CaxtonCardDelivery.Received) });
    this.deliveryOptions.push({ value: CaxtonCardDelivery.Sent.toString(), text: enumToText(CaxtonCardDelivery, CaxtonCardDelivery.Sent) });
    this.deliveryOptions.push({ value: CaxtonCardDelivery.Missing.toString(), text: enumToText(CaxtonCardDelivery, CaxtonCardDelivery.Missing) });
  }

  initCardStatusOptions() {
    this.cardStatusOptions.push({ value: CaxtonCardStatus.Activated.toString(), text: enumToText(CaxtonCardStatus, CaxtonCardStatus.Activated) });
    this.cardStatusOptions.push({ value: CaxtonCardStatus.Archived.toString(), text: enumToText(CaxtonCardStatus, CaxtonCardStatus.Archived) });
  }

  initTrialOptions() {
    this.trialService.getTrialsWithSites().subscribe({
      next: response => {
        this.trialOptions = response.map(r => new SelectOption(r.id, r.label));
        this.trialsList = response;

        this.loadCards();
      },
      error: error => {
        this.alertService.showWarningAlert("Failed to get trials for trial dropdown. Please try again or contact support");
        LogHelper.log(error);
      }
    })
  }

  loadCards(page: number = 1) {
    this.page = page;
    this.loading = true;
    const request: GetCaxtonCardsRequest = {
      page: page,
      filter: CaxtonCardTypeFilter[this.cardTypeSelect.selectedValue],
      trialId: this.searchForm.controls.trialId.value,
      searchTerm: this.searchForm.controls.searchTerm.value
    }

    this.caxtonCardService.getCaxtonCards(request).subscribe({
      next: response => {
        this.cards = response;

        this.cards.results.forEach(r => {
          if (r.trialId !== null) {
            let trialSites = this.trialsList.find(t => t.id === r.trialId);
            this.siteOptions[r.id] = trialSites?.sites.map(r => new SelectOption(r.id, `${r.name}-${r.address}`));
          }

          this.updateCardForms[r.id] = new FormGroup({
            id: new FormControl(r.id),
            name: new FormControl(r.name, Validators.required),
            delivery: new FormControl(r.delivery?.toString()),
            expiry: new FormControl(r.expiry),
            trialId: new FormControl(r.trialId),
            siteId: new FormControl(r.siteId),
            address: new FormControl(r.address),
            siteContact: new FormControl(r.siteContact),
            dateSent: new FormControl(r.dateSent),
            courierCost: new FormControl(r.courierCost),
            trackingNumber: new FormControl(r.trackingNumber),
            status: new FormControl(r.status?.toString()),
            cardInfo: new FormControl(r.cardInfo),
          });
        });

        this.loading = false;
        this.selectedCards = [];
      },
      error: error => {
        this.loading = false;
        this.alertService.showErrorResponse(error);
      }
    })
  }

  showAddCardsModal() {
    this.addCardsForm.reset();
    this.addCardsModal.show();
  }

  onAddCards() {
    this.processingModalRequest = true;
    const request = this.addCardsForm.value as AddCaxtonCardRequest;

    this.caxtonCardService.addCards(request).subscribe({
      next: () => {
        this.loadCards(this.page);
        this.processingModalRequest = false;
        this.alertService.showSuccessAlert("Cards successfully added.");
        this.addCardsModal.hide();
      },
      error: error => {
        this.processingModalRequest = false;
        this.alertService.showErrorResponse(error.error);
      }
    });
  }

  onCardSelected(selected: boolean, cardId: string) {
    if (selected) {
      this.selectedCards.push(cardId);
    } else {
      this.selectedCards = this.selectedCards.filter(id => id !== cardId);
    }
  }

  onChangesMade(cardId: string) {
    const updateCardRequest: UpdateCaxtonCardRequest = this.updateCardForms[cardId].value;
    updateCardRequest.dateSent = this.updateCardForms[cardId].get('dateSent').value !== null ? moment(this.updateCardForms[cardId].get('dateSent').value).format('DD/MMM/yyyy') : null;
    updateCardRequest.expiry = this.updateCardForms[cardId].get('expiry').value;

    this.caxtonCardService.updateCard(updateCardRequest).subscribe({
      next: () => {
        if (updateCardRequest.delivery == CaxtonCardDelivery.Sent && updateCardRequest.dateSent === null) {
          this.loadCards(this.page);
        }

        this.alertService.showSuccessAlert("Card successfully updated.");
      },
      error: error => {
        this.alertService.showErrorResponse(error.error);
      }
    })
  }

  onSiteChanged(cardId: string, event: any) {
    let site = this.siteOptions[cardId].find(s => s.value === event.value);
    this.updateCardForms[cardId].get("address").setValue(site.text);
    let card = this.cards.results.find(c => c.id === cardId);
    card.address = site.text;

    this.onChangesMade(cardId);
  }

  onTrialChanged(cardId: string, event: any) {
    let trial = this.trialsList.find(t => t.id === event.value);
    let card = this.cards.results.find(c => c.id === cardId);
    card.oracleCode = trial.oracleCode;

    card.siteId = null;
    card.address = null;
    this.updateCardForms[cardId].get('siteId').setValue(null);
    this.updateCardForms[cardId].get('address').setValue(null);

    let trialSites = this.trialsList.find(t => t.id === event.value);
    this.siteOptions[cardId] = trialSites?.sites.map(r => new SelectOption(r.id, `${r.name}-${r.address}`));

    this.onChangesMade(cardId);
  }

  onMarkAs() {
    let updateCaxtonCardsDeliveryStatus: UpdateCaxtonCardsDeliveryStatusRequest = {
      cardIds: this.selectedCards,
      delivery: CaxtonCardDelivery[this.markAsSelect.selectedValue]
    }

    this.caxtonCardService.updateCardsDeliveryStatus(updateCaxtonCardsDeliveryStatus).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Cards successfully updated.");
        this.markAsSelect.reset();
        this.selectedCards = [];
        this.loadCards(this.page);
      },
      error: error => {
        this.alertService.showErrorResponse(error.error);
      }
    })
  }

  onFilterTrialChanged(selection: TrialAutocomplete): void {
    this.assignCardsForm.controls.trialId.setValue(selection.id);

    this.siteService.retrieveSites(1, selection.id, null, null, false, 9999).subscribe({
      next: response => {
        this.assignCardsSiteOptions = response.results.map(r => new SelectOption(r.id, `${r.name}-${r.address}`));
      },
      error: error => {
        this.alertService.showWarningAlert("Failed to get sites. Please try again or contact support");
        LogHelper.log(error);
      }
    })
  }

  openAssignCardsModal() {
    this.assignCardsForm.reset();
    this.assignCardsSiteDropdown.reset();
    this.trialAutocomplete.reset();
    this.assignCardPatientView = false;
    this.assignCardsModal.show();
  }

  switchAssignCardsModalView() {
    this.assignCardPatientView = !this.assignCardPatientView;

    if (this.assignCardPatientView) {
      this.assignCardsForm.controls.siteId.reset();
      this.assignCardsSiteDropdown.reset();
      this.assignCardsForm.controls.siteContact.reset();
      this.assignCardsForm.controls.patientDetails.setValue('Redacted');
    } else {
      this.assignCardsForm.controls.patientDetails.reset();
    }
  }

  onAssignCards() {
    this.processingModalRequest = true;

    let assignCardsRequest = this.assignCardsForm.value as AssignCardsRequest;
    assignCardsRequest.cardIds = this.selectedCards;

    this.caxtonCardService.assignCards(assignCardsRequest).subscribe({
      next: () => {
        this.processingModalRequest = false;
        this.assignCardsModal.hide();
        this.loadCards(this.page);
      },
      error: error => {
        this.processingModalRequest = false;
        this.alertService.showErrorResponse(error.error);
      }
    })
  }

  onExportTrialChanged(selection: TrialAutocomplete): void {
    this.exportForm.controls.trialId.setValue(selection.id);
  }

  export() {
    this.processingModalRequest = true;
    const startDate = this.exportForm.get('startDate').value as Date;
    const endDate = this.exportForm.get('endDate').value as Date;

    let request: ExportCaxtonCardsRequest = {
      trialId: this.exportForm.get('trialId').value,
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null
    }

    this.caxtonCardService.exportCards(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("The export request was sent, please check your email.");
        this.exportModal.hide();
        this.processingModalRequest = false;
      },
      error: err => {
        this.alertService.showErrorResponse(err.error)
        this.processingModalRequest = false;
      }
    });
  }

  showExportModal() {
    this.exportForm.reset();
    this.exportTrialAutocomplete.reset();
    this.exportModal.show();
  }

    protected readonly Permissions = Permissions;
  protected readonly RoleNames = RoleNames;
}
