import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AutosuggestDropdownInputComponent } from "../autosuggest-dropdown-input/autosuggest-dropdown-input.component";
import { NumberHelper } from "../../core/helpers/number.helper";
import { UntypedFormGroup } from "@angular/forms";
import { Timezone } from "../../core/constants/timezones";

@Component({
  selector: 'app-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrl: './timezone-select.component.scss'
})
export class TimezoneSelectComponent implements OnInit, AfterViewInit {
  @ViewChild('timezoneSelect') timezoneSelect: AutosuggestDropdownInputComponent;

  @Input('parentForm') form: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('placeholder') placeholder: string = 'Select timezone...';

  @Output('valueChanged') valueChanged = new EventEmitter<string>();
  @Output('optionChanged') optionChanged = new EventEmitter<{ value: string, text: string }>();

  allTimezones: { value: string, text: string }[] = [];
  numberHelper = NumberHelper;

  constructor() {
    this.allTimezones = Timezone.getValuesForList();
  }

  ngOnInit(): void {
  }

  disable() {
    this.timezoneSelect.disable();
  }

  ngAfterViewInit() {
    if (this.form && this.controlName)
      this.timezoneSelect.setSelectedOption(this.form.get(this.controlName).value);
  }

  onCleared() {
    this.form.patchValue({ [this.controlName]: '' });
    this.valueChanged.emit();
    this.optionChanged.emit(null);
    this.form.get(this.controlName).updateValueAndValidity();
  }

  setTimezone(timezone: string) {
    if (timezone !== null && timezone !== '')
      this.timezoneSelect.setSelectedOption(timezone);
  }

  reset() {
    this.form.get(this.controlName).reset();
    this.timezoneSelect.onClear();
  }

  onTimezoneSelectChanged(timezone: string): void {
    this.form.patchValue({ [this.controlName]: timezone });
    this.valueChanged.emit(timezone);

    const option = this.allTimezones.find(t => t.value === timezone);
    if (option)
      this.optionChanged.emit(option);
  }
}

