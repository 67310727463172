<app-modal #modal class="add-visit-modal" [scrollable]="true">
  <div class="modal-header">
    <h2 class="modal-title">Add New Visit</h2>
    <button type="button" class="close" (click)="modal.hide()" aria-label="Close">
      <span aria-hidden="true"><i class="far fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" (submit)="onFormSubmit()" (mousemove)="onMouseEnterForm()">

      <table class="simple-form" aria-hidden="true">
        <tr [class.visibility-hidden]="!enableSelectPatientAndTrial">
          <th><label>Patient <sup>*</sup></label></th>
          <td colspan="2">
            <div class="form-group">
              <app-patient-autocomplete #patientAutoComplete [parentForm]="form"
                                        [required]="true" controlName="patientName"
                                        placeholder="Patient Name or Id..."></app-patient-autocomplete>
            </div>
          </td>
        </tr>
        <tr [class.visibility-hidden]="form.get('patientId').value === null || form.get('patientId').value === '' || !enableSelectPatientAndTrial">
          <th><label>Trial <sup *ngIf="isRequired(form.get('trialId'))">*</sup></label></th>
          <td colspan="2">
            <div class="form-group">
              <app-trial-autocomplete #trialAutocomplete [form]="form" controlName="trialId"
                                      placeholder="Select a trial..."></app-trial-autocomplete>
            </div>
          </td>
        </tr>
        <tbody [class.visibility-hidden]="form.get('patientId').value === '' || form.get('trialId').value === ''">
        <tr>
          <th><label>Date <sup *ngIf="isRequired(form.get('date'))">*</sup></label></th>
          <td>
            <div class="form-group" style="max-width: 250px">
              <input type="text" #dateInput class="date" data-toggle="datepicker" formControlName="date">
            </div>
          </td>
          <td class="text-right">
            <div class="form-group type-time">
              <mat-form-field>
                <input type="time" matInput formControlName="time">
              </mat-form-field>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>End Date<sup *ngIf="isRequired(form.get('endDate'))">*</sup></label></th>
          <td>
            <div class="form-group" style="max-width: 250px">
              <input type="text" #endDateInput class="date" data-toggle="datepicker" formControlName="endDate">
            </div>
          </td>
          <td class="text-right">
            <div class="form-group type-time">
              <mat-form-field>
                <input type="time" matInput formControlName="endTime">
              </mat-form-field>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Visit Title <sup *ngIf="isRequired(form.get('date'))">*</sup></label></th>
          <td colspan="2">
            <div class="form-group">
              <input type="text" formControlName="visitTitle">
            </div>
          </td>
        </tr>
        <tr>
          <th class="high-cell"><label>Description <sup *ngIf="isRequired(form.get('description'))">*</sup></label></th>
          <td colspan="2">
            <div class="form-group">
              <textarea class="form-control" formControlName="description"></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <th class="high-cell"><label>Notes<sup *ngIf="isRequired(form.get('notes'))">*</sup></label></th>
          <td colspan="2">
            <div class="form-group">
              <textarea class="form-control" formControlName="notes"></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Visit Type<sup *ngIf="isRequired(form.get('visitType'))">*</sup></label></th>
          <td colspan="2">
            <div class="form-group">
              <app-dropdown-input #visitType [parentForm]="form" name="visitType" [options]="visitTypeOptions"
                                  placeholder="Select Type..." (selectValueChanged)="onVisitTypeChanged($event)"></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr [class.visibility-hidden]="form.get('visitType')?.value !== 'OnSite'">
          <th><label>Site<sup *ngIf="isRequired(form.get('siteId'))">*</sup></label></th>
          <td colspan="2">
            <div class="form-group">
              <app-site-autocomplete #siteAutocomplete [form]="form" [trialFilter]="patient.trialId"
                                     [allowAllSites]="false"
                                     controlName="site"
                                     placeholder="Start typing to find a site..."></app-site-autocomplete>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': form.get('visitType').value != 'OnSite'}">
          <th><label>Department <sup *ngIf="isRequired(form.get('department'))">*</sup></label></th>
          <td colspan="2">
            <div class="form-group">
              <input type="text" formControlName="department">
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Designated Contacts</label></th>
          <td colspan="2">
            <div class="form-group">
              <app-input-multi-select #designatedContacts [parentForm]="form" controlName="designatedContacts"
                                      [items]="coordinatorOptions" inputPlaceholder="Please select..."></app-input-multi-select>
            </div>
          </td>
        </tr>
        <tr [class.visibility-hidden]="form.get('visitType').value !== 'OnSite'">
          <th></th>
          <td colspan="2">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Travel Requested?<sup *ngIf="isRequired(form.get('travelRequested'))">*</sup></label>
                  <app-dropdown-input #travelRequestedSelect [parentForm]="form" name="travelRequested"
                                      [options]="travelRequestedOptions" placeholder="Select..."></app-dropdown-input>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Caregiver Travelling?<sup *ngIf="isRequired(form.get('caregiverTravelling'))">*</sup></label>
                  <app-dropdown-input #caregiverTravellingSelect [parentForm]="form" name="caregiverTravelling"
                                      [options]="caregiverTravellingOptions" placeholder="Select..."></app-dropdown-input>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th></th>
          <td colspan="2">

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Booking Status<sup *ngIf="isRequired(form.get('bookingStatus'))">*</sup></label>
                  <app-dropdown-input #bookingStatusSelect [parentForm]="form" name="bookingStatus"
                                      [options]="bookingStatusOptions" placeholder="Select..."></app-dropdown-input>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Published Status<sup *ngIf="isRequired(form.get('publishedStatus'))">*</sup></label>
                  <span class="fake-field disabled"
                        [class.visibility-hidden]="form.get('publishedStatus').value !== 'Published'">Published</span>
                  <ng-container [class.visibility-hidden]="form.get('publishedStatus').value === 'Published'">
                    <app-dropdown-input #publishedStatusSelect [parentForm]="form" name="publishedStatus"
                                        [options]="publishedOptions" placeholder="Select..."></app-dropdown-input>
                  </ng-container>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Attendance<sup *ngIf="isRequired(form.get('attendance'))">*</sup></label></th>
          <td colspan="2">
            <div class="form-group">
              <app-dropdown-input #attendanceSelect [parentForm]="form" name="attendance" [options]="visitAttendanceOptions"
                                  placeholder="Select..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr *ngIf="authService.hasRole('SitesFullAccess') && authService.hasRole('TrialsFullAccess')"
            [ngClass]="{'visibility-hidden': form.get('visitType').value != 'OnSite'}">
          <th></th>
          <td>
            <a class="btn btn-outline btn-outline-slim" (click)="createSiteModal.open()">+ Add New Site</a>
          </td>
        </tr>
        <tr *ngIf="patient.diaryEnabled">
          <th></th>
          <td>
            <div class="checkbox-wrap">
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="takeMedicationAtVisit" formControlName="takeMedicationAtVisit"
                         value="true">
                  <span></span>
                </label>
                <label class="checkbox-label" for="takeMedicationAtVisit">MAZE: Medication to be taken at visit</label>
              </div>
            </div>

            <div class="checkbox-wrap">
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="urineSample3DaysBefore" formControlName="urineSample3DaysBefore"
                         value="true">
                  <span></span>
                </label>
                <label class="checkbox-label" for="urineSample3DaysBefore">MAZE: Urine sample (3 days before)</label>
              </div>
            </div>

            <div class="checkbox-wrap">
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="urineSample10DaysBefore" formControlName="urineSample10DaysBefore"
                         value="true">
                  <span></span>
                </label>
                <label class="checkbox-label" for="urineSample10DaysBefore">MAZE: Urine sample (10+ days before)</label>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
        <tr>
          <th></th>
          <td colspan="2">
            <div class="btn-wrap">
              <div class="row">
                <div class="col-6">
                  <a class="btn btn-secondary" (click)="hide()">Cancel</a>
                </div>
                <div class="col-6">
                  <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormProcessing">
                        <span *ngIf="isFormProcessing">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
                    <span *ngIf="!isFormProcessing">Add Visit</span>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>

    </form>
  </div>
</app-modal>

<app-patient-update-address #patientUpdateAddressModal (onAddressChanged)="onPatientAddressUpdated($event)"></app-patient-update-address>

<app-create-site-modal #createSiteModal (createdSite)="onNewSiteCreated($event)" [trialId]="patient.trialId">
</app-create-site-modal>
