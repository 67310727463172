import { ExpenseCategoryItem, ExpenseSubCategoryItem } from "./expense-category-list.model";
import { TrialAdminUser } from "./trial-admin-user.model";

export class TrialDetail {
  public id: string;
  public code: string;
  public label: string;
  public name: string;
  public description: string;
  public client: string;
  public sponsor: string;
  public nickname: string;
  public opportunityNumber: string;
  public expensePolicy: string;
  public internalCode: string;
  public protocolNumber: string;
  public indications: string;
  public therapeuticArea: string;
  public visitCount: number;
  public state: string;
  public created: Date;
  public allowTravelRequests: boolean;
  public allowExpenseRequests: boolean;
  public allowActivityCentre: boolean;
  public enableSmartNotifications: boolean;
  public createdBy: string;
  public supportEmail: string;
  public supportPhone: string;
  public coordinatorEmail: string;
  public termsUrl: string;
  public privacyPolicyUrl: string;
  public categories: ExpenseCategoryItem[];
  public subCategories: ExpenseSubCategoryItem[];
  public supportContacts: TrialSupportContact[];
  public translations: TrialTranslation[];
  public translationVersionId: string;
  public rideHealthEnabled: boolean;
  public startDate: Date;
  public endDate: Date;
  public timelineEnabled: boolean;
  public trialInfoEnabled: boolean;
  public dashboardEnabled: boolean;
  public allowFeedbackRequests: boolean;
  public countries: string[];
  public expensePolicyReminders: string;
  public bookingEnabled: boolean;
  public patientAutoCompletion: boolean;
  public visitTemplateExists: boolean;
  public bankAccountEnabled: boolean;
  public bankAccountSelfManagementEnabled: boolean;
  public baseCurrency: string;
  public bankAccountSelfCardManagementEnabled: boolean;
  public muvEnabled: boolean;
  public trialUsers: TrialAdminUser[] = [];
  public groundTransportOverBudgetRequirement: string;
  public accommodationOverBudgetRequirement: string;
  public rentalCarOverBudgetRequirement: string;
  public foodOverBudgetRequirement: string;
  public mileageOverBudgetRequirement: string;
  public travelOverBudgetRequirement: string;
  public parkingOverBudgetRequirement: string;
  public otherOverBudgetRequirement: string;
  public apiEnabled: boolean;
  public apiConsumerIds: string[];
  public apiConsumers: TrialApiConsumer[] = [];
  public requestTravelEmailTemplate: string;
  public magicLinkExpiryDays: number;
  public emailPatientOnVisitCreation: boolean;
  public diaryEnabled: boolean;

  getTranslation(culture: string) {
    if (this.translations !== null && this.translations.length > 0 && culture !== null && culture !== '') {
      this.translations.forEach(translation => {
        if (translation.culture.toLowerCase() === culture.toLowerCase()) {
          return translation;
        }
      });
    }
  }
}

export class TrialApiConsumer {
  public id: string;
  public name: string;
  public enabled: boolean;

  constructor(init?: Partial<TrialApiConsumer>) {
    Object.assign(this, init);
  }
}

export class TrialSupportContact {
  public constructor(public id: string, public label: string, public culture: string, public country: string, public contact: string) {
  }
}

export class TrialTranslation {
  public constructor(
    public id: string,
    public trialId: string,
    public name: string,
    public description: string,
    public expensePolicy: string,
    public culture: string) {
  }
}

export class TrialWithSites {
  id: string;
  label: string;
  oracleCode: string;
  sites: SiteItem[];
}

export class SiteItem {
  id: string;
  name: string;
  address: string;
}
